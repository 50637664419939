import React, { Component } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./service_slider.scss"

import service1img from "../images/service1img.png"
import service2img from "../images/service2img.png"
import service3img from "../images/service3img.png"
import service4img from "../images/service4img.png"
import service5img from "../images/service5img.png"

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    return (
      <div id="service_slider" className="mobile">
        <Slider {...settings}>
          <div>
            <img src={service1img} alt="" />
            <h3>物業一按</h3>
            <p>
              開心按揭服務有限公司為客户提供物業一按貸款，不查過往信貸記錄，毋需提供收入証明，不問貸款原因，不論樓齡，物業貸款額高達物業估值九成。無論客戶為了生意周轉、清還卡數、欠款、創業、辦公室擴充、裝修等，閣下只要擁有私人樓宇、村屋、唐樓、地舖、寫字樓、工廠及車位，都可致電或親臨本行歡迎查詢。
              客户只要擁有物業，並已供滿銀行按揭；
              或還在按揭機構，便可把原有一按轉至開心按揭服務有限公司；
              或在購買時需要一筆過以現金買入物業；
              便可申請物業一按貸款，款額高達物業估值九成。
            </p>
          </div>
          <div>
              <img src={service2img} alt="" />
            <h3>物業二按</h3>
            <p>
              開心按揭服務有限公司為客户提供物業二按套現無上限之貸款服務，毋需抵押樓契，不查過往信貸記錄，毋需提供收入証明，不問貸款原因，不論樓齡。閣下只要擁有私人樓宇、村屋、唐樓、地舖、寫字樓、工廠及車位，都可致電或親臨本行歡迎查詢。
              另本公司特設同步二按，當新買家購入物業申請按揭時因物業估價不足或未能通過銀行壓力測試而至批出貸款額不足，新買家便可申請同步二按把不足之差額補回去完成交易。歡迎致電或親臨本行歡迎查詢。
            </p>
          </div>
          <div>
          <img src={service3img} alt="" />
            <h3>居屋業主貸款</h3>
            <p>
              開心按揭服務有限公司為客户提供免補地價之居屋業主私人貸款，不用聯名申請，毋需抵押樓契，不查過往信貸記錄，毋需提供收入証明，不問貸款原因，不論樓齡，物業貸款額高達一佰萬或以上。閣下只要擁有居屋單位，都可致電或親臨本行歡迎查詢。
            </p>
          </div>
          <div>
          <img src={service4img} alt="" />
            <h3>單邊按揭</h3>
            <p>
              聯名業權一人可借
              開心按揭服務有限公司為客户提供物業一、二按貸款，不用聯名申請，毋需抵押樓契，不查過往信貸記錄，毋需提供收入証明，不問貸款原因，不論樓齡，物業貸款額高達物業估值九成。
            </p>
          </div>
          <div>
          <img src={service5img} alt="" />
            <h3>建期貸款、丁屋發展</h3>
            <p>
              若閣下正拓展村屋，本公司樂意提供發展村屋建築費及補地價之融資服務。不論建築物、舊屋重建融資貸款或農地按揭，閣下只要提供開工紙或承建商合約，都可致電或親臨本行歡迎查詢，資料絕對保密。
            </p>
          </div>
        </Slider>
      </div>
    )
  }
}
