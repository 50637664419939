import React from "react"
import service_header from "../images/services_header.png"
import application_header from "../images/application_header.png"
import terms_header from "../images/terms_header.png"
import "./header.scss"

export default function Header(props) {
  const headerURL =
    props.text === "application_header"
      ? application_header
      : props.text === "service_header"
      ? service_header
      : props.text === "terms_header"
      ? terms_header
      : null

  const headerText =
    props.text === "application_header"
      ? "申請辦法"
      : props.text === "service_header"
      ? "貸款服務"
      : props.text === "terms_header"
      ? "聲明及條款"
      : null

  return (
    <div
      className="header"
      style={{
        backgroundImage: `url(${headerURL})`,
      }}
    >
      <h1 className="header_title">{headerText}</h1>
    </div>
  )
}
